<template>
  <div v-if="session">
    <h2>PEGAWAI PEMERINTAH DENGAN PERJANJIAN KERJA</h2>

    <v-divider class="mt-3 mb-7"></v-divider>

    <div>
      <v-card class="px-3 pt-4" outlined>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="2">
              <v-img :src="foto" v-if="foto"></v-img>
              <v-img src="@/assets/gambar/no_pic_male.png" v-else></v-img>

              <v-btn
                block
                color="primary"
                x-small
                depressed
                class="mt-2"
                @click="editPhoto()"
                v-if="
                  session.authorities.some(
                    (item) => item === 'CRUD_ADMINISTRATOR'
                  )
                "
              >
                EDIT FOTO
              </v-btn>
            </v-col>

            <v-col cols="12" md="10" class="mt-5" v-if="data">
              <div class="mb-5">
                <h1>
                  {{ data.nama }}
                </h1>
              </div>

              <div v-if="rwjabatan.unor">
                <v-row v-if="rwjabatan.unor.nama != null">
                  <v-col cols="12" md="6">
                    <div class="mb-5">
                      <strong> Unit Organisasi Induk : </strong>
                      <div>{{ rwjabatan.unor.atasan.nama.toUpperCase() }}</div>
                    </div>

                    <div v-if="data.unor">
                      <strong> Unit Organisasi : </strong>
                      <div>{{ rwjabatan.unor.nama.toUpperCase() }}</div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="mb-5">
                      <strong> Nomor Induk Pegawai : </strong>
                      <div>{{ data.nipBaru }}</div>
                    </div>

                    <div class="mb-5">
                      <strong> Jabatan : </strong>
                      <div v-if="rwjabatan.jenis_jabatan">
                        {{ rwjabatan.jenis_jabatan.nama.toUpperCase() }}
                      </div>
                      <div>{{ rwjabatan.nama_jabatan.toUpperCase() }}</div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>

    <div>
      <CEditphoto />
    </div>

    <div class="mt-10">
      <router-view />
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import CEditphoto from "@/components/photo/edit";

import refreshView from "@/store/photo/refreshView";
import modal_editState from "@/store/photo/modal_edit";
import jabview from "@/store/asn/pppk/rwperker/view";

export default {
  components: {
    CEditphoto,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    jabref: {
      get() {
        return jabview.state.Refresh;
      },
    },
  },

  watch: {
    refresh() {
      this.getFoto();
    },

    jabref() {
      this.getJabatan();
    },
  },

  data: () => ({
    token: Cookie.get("token"),

    foto: "",
    session: "",

    data: {},
    rwjabatan: {},
  }),

  mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getJabatan();
    this.getFoto();
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;

      const url =
        process.env.VUE_APP_ASN + "datautama/nip/" + this.$route.params.id;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.mapData.DataUtama;
            this.loading = false;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getFoto() {
      const url = process.env.VUE_APP_ASN + "photos/" + this.$route.params.id;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((response) => {
          refreshView.commit("refreshData", false);
          this.foto = URL.createObjectURL(response.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getJabatan() {
      this.loading = true;

      const url =
        process.env.VUE_APP_ASN +
        "pppk/perjanjiankerja/nip/" +
        this.$route.params.id;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.loading = false;
          let rwjabatans = response.data.mapData.data;
          this.rwjabatan = rwjabatans.at(-1);
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log("Error", error.message);
        });
    },

    editPhoto() {
      modal_editState.commit("toggleModal", true);
    },
  },
};
</script>
